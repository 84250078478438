const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['booking-setting'] = require('../middleware/booking-setting.js')
middleware['booking-setting'] = middleware['booking-setting'].default || middleware['booking-setting']

middleware['broadcast-chat'] = require('../middleware/broadcast-chat.js')
middleware['broadcast-chat'] = middleware['broadcast-chat'].default || middleware['broadcast-chat']

middleware['desktop'] = require('../middleware/desktop.js')
middleware['desktop'] = middleware['desktop'].default || middleware['desktop']

middleware['goldplus-owner'] = require('../middleware/goldplus-owner.js')
middleware['goldplus-owner'] = middleware['goldplus-owner'].default || middleware['goldplus-owner']

middleware['goldplus-submission-owner'] = require('../middleware/goldplus-submission-owner.js')
middleware['goldplus-submission-owner'] = middleware['goldplus-submission-owner'].default || middleware['goldplus-submission-owner']

middleware['mobile'] = require('../middleware/mobile.js')
middleware['mobile'] = middleware['mobile'].default || middleware['mobile']

middleware['prime-check-listing'] = require('../middleware/prime-check-listing.js')
middleware['prime-check-listing'] = middleware['prime-check-listing'].default || middleware['prime-check-listing']

middleware['redirect-url-query-param'] = require('../middleware/redirect-url-query-param.js')
middleware['redirect-url-query-param'] = middleware['redirect-url-query-param'].default || middleware['redirect-url-query-param']

middleware['route'] = require('../middleware/route.js')
middleware['route'] = middleware['route'].default || middleware['route']

export default middleware
