import { PRIME_PLACEMENT_LOCALSTORAGE } from '~/constants/prime';

export const state = () => ({
  selectedListingData: null,
  selectedPeriodData: null,
  emptyStatePrime: false,
  totalActiveKosPrime: 0,
  totalPrimeAbleKos: 0,
  dataPackagePlacement: [],
  selectedPackagePlacement: null,
  isLoadingPackagePlacement: false,
  isTotalPrimeListingAlreadyCalled: false,
});

export const getters = {
  isOwnerHaveActiveListing(state) {
    return state.totalActiveKosPrime === 0;
  },
  isOwnerHavePrimeAbleListing(state) {
    return state.totalActiveKosPrime > 0 && state.totalPrimeAbleKos === 0;
  },
};

export const mutations = {
  setSelectedListingData(state, data = null) {
    state.selectedListingData = data;
  },
  setSelectedPeriodData(state, data = null) {
    state.selectedPeriodData = data;
  },
  setEmptyStatePrime(state, data = false) {
    state.emptyStatePrime = data;
  },
  setTotalActiveKosPrime(state, data = 0) {
    state.totalActiveKosPrime = data;
  },
  setTotalPrimeAbleKos(state, data = 0) {
    state.totalPrimeAbleKos = data;
  },
  setPackagePlacement(state, placements) {
    state.dataPackagePlacement = placements;
    state.selectedPackagePlacement = placements[0] || null;
  },
  setLoadingPackagePlacement(state, isLoading) {
    state.isLoadingPackagePlacement = isLoading;
  },
  setSelectedPackagePlacement(state, selectedPlacement) {
    state.selectedPackagePlacement = selectedPlacement;
  },
  setTotalPrimeListingAlreadyCalled(state, isCalled) {
    state.isTotalPrimeListingAlreadyCalled = isCalled;
  },
};

export const actions = {
  async getPrimePackagePlacement({ commit }) {
    commit('setLoadingPackagePlacement', true);

    try {
      const response = await this.$api.getPrimePackagePlacement();

      if (response.data) {
        const { placements } = response.data;
        commit('setPackagePlacement', placements);
      }
    } catch (error) {
      this.$error.report(error);
      this.$toast.show({ message: 'Terjadi galat, silakan coba lagi.' });
    } finally {
      commit('setLoadingPackagePlacement', false);
    }
  },

  setSelectedPlacementToLocalStorage({ state }) {
    localStorage.setItem(
      PRIME_PLACEMENT_LOCALSTORAGE,
      JSON.stringify(state.selectedPackagePlacement)
    );
  },

  async getTotalPrimeListing({ commit }) {
    try {
      const response = await this.$api.getRoomTypeActive({
        for: 'mamiprime',
      });

      if (response) {
        const totalActiveKos = response.data.total_kost || 0;
        const totalPrimeAbleKos = response.data.total_primeable_kost || 0;
        commit('setTotalActiveKosPrime', totalActiveKos);
        commit('setTotalPrimeAbleKos', totalPrimeAbleKos);
        commit('setTotalPrimeListingAlreadyCalled', true);
      }
    } catch (error) {
      this.$error.report(error);
    }
  },
};
