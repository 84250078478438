export default ({ store, route, redirect }) => {
  const { isTotalPrimeListingAlreadyCalled } = store.state.prime;

  if (isTotalPrimeListingAlreadyCalled) return;

  const validateUserPrime = () => {
    const isOwnerDontHaveActiveListing = store.state.prime.totalActiveKosPrime;
    if (isOwnerDontHaveActiveListing === 0) {
      return redirect('/prime');
    }
  };

  store.dispatch('prime/getTotalPrimeListing').then(() => {
    validateUserPrime();
  });
};
