import { getAccessToken, getRefreshToken, setToken } from '../cookiesToken';
import redirectLogin from '~/utils/redirectLogin';
import { clearAuthTracker } from '~/utils/authTracker';

/**
 * Axios interceptor for response
 * When getting 200, just pass it
 */
export function callback(response) {
  return response;
}

/**
 * When getting 401, try to refresh token then call api again
 * Except it, toast it to screen
 * this = vue instance
 */
export function error(error) {
  const originalRequest = error.config;
  if (error.response) {
    // 401, try to refresh token then call api again
    if (error.response.status === 401) {
      // When failed on refreshing token, redirect to login page
      if (originalRequest.url === '/oauth/token') {
        /*
         * When response code is 401, try to refresh the token.
         * Eject the interceptor so it doesn't loop in case
         * token refresh causes the 401 response
         */
        clearAuthTracker();
        this.$axios.interceptors.response.eject(window.responseInterceptor);
        redirectLogin();
        this.$error.report(new Error('refreshing token failed'));
        return Promise.reject(error);
      }

      // When access_token can't be used anymore(expired), refresh that token
      const refreshToken = getRefreshToken();
      return this.$api
        .refreshToken({
          refresh_token: refreshToken,
        })
        .then(res => {
          if (res.status === 200) {
            setToken(res.data);
            originalRequest.headers.Authorization = `Bearer ${getAccessToken()}`;
            return this.$axios(originalRequest);
          }
          return null;
        });
    }

    /**
     * Reports specific HTTP errors to the error tracking system:
     * - Server-side errors (500-599)
     * - Client-side errors:
     *   - 400 (Bad Request): Usually malformed request syntax
     *   - 404 (Not Found): Requested resource doesn't exist
     */
    if (
      error.response.status >= 500 ||
      error.response.status === 400 ||
      error.response.status === 404
    ) {
      this.$error.report(error);
    }
  }

  return Promise.reject(error);
}
